










import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Loading extends Vue {
    active = false

    get isWxbrower() {
        return this.$store.state.login.isWxBrower
    }

    mounted() {
        console.log(this.$store.state.login.isWxBrower)
        if (!this.$store.state.login.isWxBrower) {
            this.$router.push({
                name: 'phoneLogin'
            })
        }

        setTimeout(() => {
            this.active = true
        }, 3000)
    }

    goLogin() {
        this.$router.push({
            name: 'phoneLogin'
        })
    }
}
